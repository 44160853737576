import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';

const TempoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      studio: file(relativePath: { eq: "tempo-studio-2.png" }) {
        ...projectImage
      }
      leaderboard: file(relativePath: { eq: "tempo-leaderboard.png" }) {
        ...projectImage
      }
      class: file(relativePath: { eq: "tempo-class.png" }) {
        ...projectImage
      }
      post: file(relativePath: { eq: "tempo-post-class.png" }) {
        ...projectImage
      }
      live: file(relativePath: { eq: "tempo-live.png" }) {
        ...projectImage
      }
      vision: file(relativePath: { eq: "tempo-vision.png" }) {
        ...projectImage
      }
    }
  `);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <h1>Tempo - Studio</h1>
          <ul>
            <li>
              Technical <strong>lead for major features</strong> like
              range-of-motion form feedback,{' '}
              <strong>real-time messaging</strong>, streaming music over
              bluetooth, weight recognition
            </li>
            <li>
              Introduced tools such as React Redux, Storybook and clean code,
              practices helping Tempo to{' '}
              <strong>scale the frontend engineering team</strong>
            </li>
            <li>
              Designing and implementing a leaderboard feature with affinity
              groups scalable to <strong>100,000 members</strong> and also
              replacing ZeroMQ with a <strong>Kafka</strong> based architecture
              that solved client performance bottlenecks
            </li>
            <li>
              <strong>Running experiments</strong> using Statsig and
              implementing a post-class survey to gather actionable data from
              our members
            </li>
            <li>
              Collaborated early and often with product management and design to{' '}
              <strong>brainstorm, scope, estimate</strong>  and prioritize new
              product features and improvements
            </li>
            <li>
              Resolving incidents using tools like Datadog, Sentry and Amplitude
              and writing post-mortems for each incident
            </li>
            <li>
              <strong>Sharing knowledge</strong> and fostering collaboration
              through engineering book club, frontend collabs and{' '}
              <strong>pair programming</strong>
            </li>
          </ul>
          <div className="box alt">
            <div className="row gtr-uniform">
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.studio.childImageSharp.fluid}
                    alt="tempo studio"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.leaderboard.childImageSharp.fluid}
                    alt="tempo leaderboard"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.vision.childImageSharp.fluid}
                    alt="tempo vision"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.class.childImageSharp.fluid}
                    alt="tempo class"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.post.childImageSharp.fluid}
                    alt="tempo post class"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.live.childImageSharp.fluid}
                    alt="tempo live"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TempoPage;
